import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import IProject from "../../interfaces/Project";
import "./../map/__map.scss";
import DraggableMarker from "./DraggableMarker";
import DefaultIcon from "./Icon";
interface MapProps {
	editFlag: boolean;
	projects?: Array<IProject>;
	form?: Partial<IProject | undefined | null>;
	setPosition?:
		| React.Dispatch<
				React.SetStateAction<Partial<IProject | null | undefined>>
		  >
		| undefined;
}
const Map: React.FC<MapProps> = ({ editFlag, setPosition, form, projects }) => {
	return (
		<>
			<MapContainer
				center={[50.0, 15.0]}
				zoom={4}
				scrollWheelZoom={false}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
				/>
				{editFlag ? (
					<DraggableMarker handlePosition={setPosition} form={form} />
				) : projects && projects?.length > 0 ? (
					<>
						{projects?.map((project, index) => {
							return (
								<Marker
									position={[
										project?.latitude || 51.505,
										project?.longitude || -0.09,
									]}
									icon={DefaultIcon}
									key={index}
								>
									<Popup>
										{
											<>
												<label>{project.name}</label>
												<br />
												<a
													href={project.link}
													target="_blank"
													rel="noreferrer"
												>
													{project.link}
												</a>
											</>
										}
									</Popup>
								</Marker>
							);
						})}
					</>
				) : null}
			</MapContainer>
		</>
	);
};

export default Map;
