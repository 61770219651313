import { useState } from "react";
import { PlusLg, DashLg } from "react-bootstrap-icons";
import ICategory from "../../interfaces/Category";
interface AccordionItemProps {
	title: string;
	categoryItems: Array<ICategory> | undefined;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
	title,
	categoryItems,
}) => {
	const [active, setActive] = useState(false);

	if (categoryItems) {
		if (categoryItems?.length > 0) {
			return (
				<>
					<li className={`accordion-item ${active ? "active" : ""}`}>
						<button
							className="btn transparent"
							onClick={() => setActive(!active)}
						>
							<label>
								{title}
								{active ? <DashLg /> : <PlusLg />}
							</label>
						</button>
						<div className={`item-wrapper ${active ? "open" : ""}`}>
							{categoryItems?.map((item) => {
								if (item.parent_id === null) {
									return (
										<>
											<div
												className="item"
												key={`${item.id}`}
											>
												{item.name}
											</div>
										</>
									);
								} else {
									const find = categoryItems?.find(
										(search) => search.id === item.parent_id
									);
									return (
										<>
											<div
												className="item"
												key={`${item.id}`}
											>
												{find?.name}: {item.name}
											</div>
										</>
									);
								}
							})}
						</div>
					</li>
				</>
			);
		}
	}

	return (
		<>
			<li className={`accordion-item ${active ? "active" : ""}`}>
				<button
					className="btn transparent"
					onClick={() => setActive(!active)}
				>
					{title}
					{active ? <DashLg /> : <PlusLg />}
				</button>
				<div className={`item-wrapper ${active ? "open" : ""}`}>
					<div className="item">{`No ${title} categories`}</div>
				</div>
			</li>
		</>
	);
};

export default AccordionItem;
