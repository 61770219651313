import React, { useState, useEffect, ChangeEvent } from "react";
import { PersonCircle, Search } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import Table from "./Table";
import Footer from "./Footer";
import Map from "./map/Map";
import axios from "axios";
import { toast } from "react-toastify";
import logo from "./../assets/logos/sied_logo_web.png";
import "./../css/__home.scss";
import IProject from "../interfaces/Project";
import ICountry from "../interfaces/Country";

const Home: React.FC = () => {
	const history = useHistory();
	const [projects, setProjects] = useState<Array<IProject>>([]);
	const [countries, setCountries] = useState<Array<ICountry>>([]);
	const [filterCountry, setFilterCountry] = useState<number | null>(null);
	const [filtered, setFiltered] = useState<Array<IProject>>();
	const [userName, setUserName] = useState<string | null>(null);
	const fetchProjects = async () => {
		axios
			.get("project")
			.then((res) => {
				setProjects(res.data);
			})
			.catch((error) => {
				console.log("error: ", error);
				return toast.error("error while fetching", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			});
	};
	const fetchCountries = async () => {
		axios
			.get("country")
			.then((res) => {
				setCountries(res.data);
			})
			.catch((error) => {
				console.log("error: ", error);
				return toast.error("error while fetching,", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			});
	};

	/**
	 * Filter-Logic for filtering countries
	 */
	const handleFilter = () => {
		if (filterCountry) {
			setFiltered(
				projects?.filter((project) => {
					return project.country.id === filterCountry;
				})
			);
		} else {
			setFiltered(projects);
		}
	};

	//Cleanup-Effect
	useEffect(() => {
		return () => {
			setUserName(null);
			setProjects([]);
			setCountries([]);
		};
	}, []);

	useEffect(() => {
		setUserName(localStorage.getItem("user-name"));
		fetchProjects();
		fetchCountries();
	}, [userName]);

	/**
	 * Effects for filtering
	 */
	useEffect(() => {
		setFiltered(projects);
		setCountries(countries);
	}, []);

	useEffect(() => {
		setFiltered(projects);
		setCountries(countries);
	}, [countries, projects]);

	//Actual filtering
	useEffect(() => {
		handleFilter();
	}, [filterCountry]);

	const handleLogout = async () => {
		axios.post("logout").then((res) => {
			localStorage.removeItem("user-name");
			history.push("/");
		});
	};

	const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
		setFilterCountry(parseInt(event.currentTarget.value));
	};

	return (
		<>
			<div className="logo-container">
				<img src={logo} alt="sied-logo"></img>
			</div>
			<div className="nav-container">
				<div className="user-container">
					<PersonCircle />
					{userName ? (
						<h3>Hello {userName}</h3>
					) : (
						<h3>Hello guest</h3>
					)}
					{userName ? (
						<button className="btn alert" onClick={handleLogout}>
							Logout
						</button>
					) : (
						<button
							className="btn success"
							onClick={() => {
								history.push("/");
							}}
						>
							Login
						</button>
					)}
				</div>
			</div>
			<div className="filter-container">
				<select onChange={handleSelectChange}>
					<option value="">Select a country</option>
					{countries?.map((country, index) => {
						return (
							<option value={country.id} key={index}>
								{country.name}
							</option>
						);
					})}
				</select>
				<Search />
			</div>
			<Map editFlag={false} projects={filtered} />
			<Table projects={projects} fetchData={fetchProjects} />
			<Footer />
		</>
	);
};
export default Home;
