import "./../css/__register.scss";
import Logo from "./../assets/logos/sied_logo_web.png";
import { ChangeEvent, FormEvent, useState } from "react";
import IUser from "./../interfaces/User";
import axios from "axios";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const Register = () => {
	const history = useHistory();

	const [formData, setFormData] = useState<IUser>({
		name: "",
		publicId: "",
		email: "",
		password: "",
	});

	const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		return await axios
			.post(`/register`, formData)
			.then((res) => {
				console.log("response: ", res.data);
				history.push("/");
				return toast.success("Successfully registered", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((error) => {
				console.log("error: ", error);
				error.response.status === 400
					? toast.error("Email already in use", {
							position: toast.POSITION.BOTTOM_CENTER,
							closeOnClick: true,
							hideProgressBar: false,
							draggable: true,
							progress: undefined,
					  })
					: toast.error("Something went wrong while registering", {
							position: toast.POSITION.BOTTOM_CENTER,
							closeOnClick: true,
							hideProgressBar: false,
							draggable: true,
							progress: undefined,
					  });
				return toast;
			});
	};

	return (
		<>
			<div className="card-container">
				<form onSubmit={handleSubmit}>
					<div className="logo">
						<img src={Logo} alt="sied-logo" />
					</div>
					<div className="input">
						<label>Name</label>
						<input
							type="text"
							placeholder="Please enter a user name"
							onChange={handleInput}
							value={formData?.name}
							name="name"
						/>
					</div>
					<div className="input">
						<label>Email</label>
						<input
							type="email"
							placeholder="Please enter an Email"
							onChange={handleInput}
							value={formData?.email}
							name="email"
						/>
					</div>
					<div className="input">
						<label>Passwort</label>
						<input
							type="password"
							placeholder="Please enter a password"
							onChange={handleInput}
							value={formData?.password}
							name="password"
						/>
					</div>
					<div className="container flex">
						<button className="btn success" type="submit">
							Sign in
						</button>
						<button
							className="btn"
							onClick={() => {
								history.push("/");
							}}
						>
							Back to Login
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default Register;
