import React, { MouseEvent, ChangeEvent, useState, FormEvent } from "react";
import { useHistory } from "react-router-dom";
import Logo from "./../assets/logos/sied_logo_web.png";
import IUser from "./../interfaces/User";
import axios from "axios";
import { toast } from "react-toastify";

import "./../css/__login.scss";

const Login = () => {
	const history = useHistory();
	const [formData, setFormData] = useState<IUser>({
		name: "",
		publicId: "",
		email: "",
		password: "",
	});

	const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFormData({
			name: "",
			publicId: "",
			email: "",
			password: "",
		});
	};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		axios
			.post(`login`, formData)
			.then((res) => {
				localStorage.setItem("user-name", res.data.name);
				history.push("/home");
			})
			.catch((error) => {
				console.log("error: ", error);
				return toast.error("invalid login", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			});
	};

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		switch (event.currentTarget.name) {
			case "register":
				history.push("/register");
				break;
			default:
				localStorage.clear();
				history.push("/home");
				break;
		}
	};

	return (
		<>
			<div className="card-container">
				<form onSubmit={handleSubmit}>
					<div className="logo">
						<img src={Logo} alt="sied-logo" />
					</div>
					<div className="input">
						<label>Email</label>
						<input
							type="email"
							placeholder="Email"
							onChange={handleInput}
							value={formData?.email}
							name="email"
						/>
					</div>
					<div className="input">
						<label>Passwort</label>
						<input
							type="password"
							placeholder="Passwort"
							onChange={handleInput}
							value={formData?.password}
							name="password"
						/>
					</div>
					<div className="container flex">
						<button
							className=" btn alert"
							type="reset"
							onClick={handleReset}
						>
							Abort
						</button>
						<button className=" btn success" type="submit">
							Login
						</button>
					</div>
					<div className="container flex">
						<button
							className="btn"
							type="button"
							onClick={handleClick}
						>
							continue without login
						</button>
						or
						<button
							className="btn"
							type="button"
							name="register"
							onClick={handleClick}
						>
							sign in
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default Login;
