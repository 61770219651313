import React, { useEffect, useState } from "react";
import {
	InfoCircle,
	Pencil,
	Trash,
	PlusLg,
	Search,
	XCircle,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import InfoModal from "./modals/InfoModal";
import EditModal from "./modals/EditModal";
import YesNoModal from "./modals/YesNoModal";
import "./../css/__table.scss";
import axios from "axios";
import ICountry from "../interfaces/Country";
import IProject from "../interfaces/Project";

interface TableProps {
	projects: Array<IProject>;
	fetchData: () => Promise<void>;
}

const Table: React.FC<TableProps> = ({ projects, fetchData }) => {
	const [showInfo, setShowInfo] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showYesNo, setShowYesNo] = useState(false);
	const [editFlag, setEditFlag] = useState<boolean | null>(null);
	const [currentProject, setCurrentProject] = useState<
		Partial<IProject | null>
	>({
		name: "",
		publicId: "",
		start: null,
		end: null,
		description: "",
		link: "",
		number_of_staff: null,
		number_of_members: null,
		number_of_users: null,
		other: "",
		latitude: null,
		longitude: null,
		innovative_character: undefined,
		sustainability: undefined,
		social_interaction: undefined,
		social_cohesion: undefined,
		social_protection: undefined,
		transferability: undefined,
		transition: undefined,
		deleteDate: null,
		country: {},
		categories: [],
	});
	const [countries, setCountries] = useState<Array<ICountry>>();
	const [filtered, setFiltered] = useState<Array<IProject>>([]);
	const [filterName, setFilterName] = useState<string>("");

	const fetchCountries = () => {
		axios
			.get("country")
			.then((res) => {
				setCountries(res.data);
			})
			.catch((error) => {
				console.log("error: ", error);
				return toast.error("error while fetching", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			});
	};
	//Cleanup
	useEffect(() => {
		return () => {
			setCountries([]);
			setFiltered([]);
		};
	}, []);

	useEffect(() => {
		fetchCountries();
		setFiltered(projects);
	}, [projects, filterName]);

	/**
	 * Filter-Logic
	 *
	 * g-flag: global search
	 * i-flag: incase sensitive
	 * ^-flag: looks at the beginning of the regexp
	 * *-flag: 0 or more occurences
	 */
	const handleFilter = () => {
		if (filterName !== "") {
			const regex = new RegExp(`^(${filterName}.*)`, "gi");
			setFiltered(
				projects?.filter((project) => {
					return project.name.match(regex);
				})
			);
		}
	};

	//Actual filtering
	useEffect(() => {
		handleFilter();
	}, [filterName]);

	const resetCurrentProject = () => {
		setCurrentProject({
			name: "",
			publicId: "",
			start: null,
			end: null,
			description: "",
			link: "",
			number_of_staff: null,
			number_of_members: null,
			number_of_users: null,
			other: "",
			latitude: null,
			longitude: null,
			innovative_character: undefined,
			sustainability: undefined,
			social_interaction: undefined,
			social_cohesion: undefined,
			social_protection: undefined,
			transferability: undefined,
			transition: undefined,
			deleteDate: undefined,
			country: {},
			categories: [],
		});
	};

	return (
		<>
			<div className="table container">
				<div className="inputGroup">
					<div className="filter-container">
						<div className="input">
							<input
								placeholder="Please provide a project name"
								value={filterName}
								onChange={(event) => {
									setFilterName(event.currentTarget.value);
								}}
							/>
							{filterName ? (
								<button
									className="btn transparent"
									onClick={() => setFilterName("")}
								>
									x
								</button>
							) : null}
							<Search />
						</div>
					</div>
					<div className="btn-container">
						<button
							className="btn success"
							onClick={() => {
								setEditFlag(false);
								resetCurrentProject();
								setShowEdit(!showEdit);
							}}
						>
							<PlusLg />
							New Project
						</button>
					</div>
				</div>
				{filtered?.length > 0 ? (
					<table className="table table-project">
						<thead>
							<tr>
								<th>Name</th>
								<th>Start</th>
								<th>End</th>
								<th>Country</th>
								<th>Description</th>
								<th>Website</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{filtered?.map((project, index) => {
								return (
									<tr key={index}>
										<td>{project?.name}</td>
										<td>{project?.start}</td>
										<td>{project?.end}</td>
										<td>{project?.country?.name}</td>
										<td
											style={{
												textOverflow: "ellipsis ",
											}}
										>
											{project.description}
										</td>
										<td>
											<a
												href={project.link}
												target="_blank"
												rel="noreferrer"
											>
												{project.link}
											</a>
										</td>
										<td>
											<button
												className="btn transparent"
												onClick={() => {
													setCurrentProject(project);
													setShowInfo(!showInfo);
												}}
											>
												<InfoCircle />
											</button>
											<button
												className="btn transparent"
												onClick={() => {
													setCurrentProject(project);
													setEditFlag(true);
													setShowEdit(!showEdit);
												}}
											>
												<Pencil />
											</button>
											<button
												value={`${project?.publicId}`}
												className="btn transparent"
												onClick={() => {
													setCurrentProject(project);
													setShowYesNo(!showYesNo);
												}}
											>
												<Trash />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				) : (
					<>
						<div className="result-container">
							<XCircle />
							<label>No Results</label>
						</div>
					</>
				)}
			</div>
			<InfoModal
				show={showInfo}
				handleShow={setShowInfo}
				project={currentProject}
			/>
			<EditModal
				editFlag={editFlag}
				show={showEdit}
				handleShow={setShowEdit}
				project={currentProject}
				countries={countries}
				fetchData={fetchData}
			/>
			<YesNoModal
				show={showYesNo}
				project={currentProject}
				handleShow={setShowYesNo}
				fetchData={fetchData}
			/>
		</>
	);
};

export default Table;
