import React, { useEffect, useState } from "react";
import ICategory from "../../interfaces/Category";
import AccordionItem from "./AccordionItem";
import "./__accordion.scss";

interface AccordionProps {
	categories: Array<ICategory> | undefined;
}

const Accordion: React.FC<AccordionProps> = ({ categories }) => {
	/**
	 * Various states for saving the categories
	 */
	const [fieldOfServices, setFieldOfServices] = useState<
		Array<ICategory> | undefined
	>([]);
	const [logicOfServices, setLogicOfServices] = useState<
		Array<ICategory> | undefined
	>([]);
	const [activityOfServices, setActivityOfServices] = useState<
		Array<ICategory> | undefined
	>([]);
	const [statusOfTheProvider, setStatusOfTheProvider] = useState<
		Array<ICategory> | undefined
	>([]);
	const [targetGroup, setTargetGroup] = useState<
		Array<ICategory> | undefined
	>([]);
	const [typeOfInnovation, setTypeOfInnovation] = useState<
		Array<ICategory> | undefined
	>([]);
	const [originsOfInnovation, setOriginsOfInnovation] = useState<
		Array<ICategory> | undefined
	>([]);
	const [
		effectivenessFromUserPrespective,
		setEffectivenessFromUserPrespective,
	] = useState<Array<ICategory> | undefined>([]);
	const [costEffectiveness, setCostEffectiveness] = useState<
		Array<ICategory> | undefined
	>([]);
	const [qualityOfLife, setQualityOfLife] = useState<
		Array<ICategory> | undefined
	>([]);
	const [moreInclusion, setMoreInclusion] = useState<
		Array<ICategory> | undefined
	>([]);
	const [participationOfTheUser, setParticipationOfTheUser] = useState<
		Array<ICategory> | undefined
	>([]);
	const [socialReturnOnInvestment, setSocialReturnOnInvestment] = useState<
		Array<ICategory> | undefined
	>([]);

	/** Filtering for categories */
	useEffect(() => {
		setFieldOfServices(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "field of service"
			)
		);
		setLogicOfServices(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "logic of service"
			)
		);
		setActivityOfServices(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "activity of service"
			)
		);
		setStatusOfTheProvider(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "status of the provider organization"
			)
		);
		setTargetGroup(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "target group"
			)
		);
		setTypeOfInnovation(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "type of innovation"
			)
		);
		setOriginsOfInnovation(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "origins of innovation"
			)
		);
		setEffectivenessFromUserPrespective(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "effectiveness from a user perspective"
			)
		);
		setCostEffectiveness(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "cost effectiveness"
			)
		);
		setQualityOfLife(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "quality of life"
			)
		);
		setMoreInclusion(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "more inclusion"
			)
		);
		setParticipationOfTheUser(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "participation of the user"
			)
		);
		setSocialReturnOnInvestment(
			categories?.filter(
				(category: ICategory | undefined) =>
					category?.type === "social return on investment"
			)
		);
	}, [categories]);

	return (
		<>
			<ul className="accordion">
				<AccordionItem
					title={"Field of Service"}
					categoryItems={fieldOfServices}
					key={"Field of Service"}
				/>
				<AccordionItem
					title={"Logic of Service"}
					categoryItems={logicOfServices}
					key={"Logic of Service"}
				/>
				<AccordionItem
					title={"Activity of Service"}
					categoryItems={activityOfServices}
					key={"Activity of Service"}
				/>
				<AccordionItem
					title={"Status of the provider organization"}
					categoryItems={statusOfTheProvider}
					key={"Status of the provider organization"}
				/>
				<AccordionItem
					title={"Target group"}
					categoryItems={targetGroup}
					key={"Target group"}
				/>
				<AccordionItem
					title={"Type of innovation"}
					categoryItems={typeOfInnovation}
					key={"Type of innovation"}
				/>
				<AccordionItem
					title={"Origins of innovation"}
					categoryItems={originsOfInnovation}
					key={"Origins of innovation"}
				/>
				<AccordionItem
					title={"Effectiveness from a user perspective"}
					categoryItems={effectivenessFromUserPrespective}
					key={"Effectiveness from a user perspective"}
				/>
				<AccordionItem
					title={"Cost effectiveness"}
					categoryItems={costEffectiveness}
					key={"Cost effectiveness"}
				/>
				<AccordionItem
					title={"Quality of life"}
					categoryItems={qualityOfLife}
					key={"Quality of life"}
				/>
				<AccordionItem
					title={"More inclusion"}
					categoryItems={moreInclusion}
					key={"More inclusion"}
				/>
				<AccordionItem
					title={"Participation of the user"}
					categoryItems={participationOfTheUser}
					key={"Participation of the user"}
				/>
				<AccordionItem
					title={"Social return on investment"}
					categoryItems={socialReturnOnInvestment}
					key={"Social return on investment"}
				/>
			</ul>
		</>
	);
};

export default Accordion;
