import React, { useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import "./__modal.scss";
import Accordion from "./../accordion/Accordion";
import IProject from "./../../interfaces/Project";

interface InfoProps {
	project: Partial<IProject | null | undefined>;
	show: boolean;
	handleShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const InfoModal: React.FC<InfoProps> = ({ show, handleShow, project }) => {
	/**
	 * Listener for Key Events.
	 * Handles Escape Key for closing Modal
	 */
	useEffect(() => {
		const close = (e: { key: string }) => {
			if (e.key === "Escape") {
				handleShow(false);
			}
		};
		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close); //Cleanup function on close
	}, []);

	if (show) {
		return (
			<>
				<div className="container flex fd-c modal">
					<div className="modal modal-content">
						<div className="container grid column-90-10 modal-header">
							<h2 className="modal modal-title">
								{project?.name}
							</h2>
							<button
								className="btn alert"
								onClick={() => {
									handleShow(false);
								}}
							>
								<XLg />
							</button>
						</div>
						<div className="modal modal-body">
							<div className="container grid column half-half">
								<div>Description</div>
								<p>{project?.description}</p>
								<div>Begin</div>
								<div>{project?.start}</div>
								<div>End</div>
								<div>{project?.end}</div>
								<div>Link</div>
								<div>
									<a
										href={`${project?.link}`}
										target="_blank"
										rel="noreferrer"
									>
										Website
									</a>
								</div>
								<div>Country</div>
								<div>{project?.country?.name}</div>
								<div>Number of members</div>
								<div>
									{project?.number_of_members ||
										"No information"}
								</div>
								<div>Number of staff</div>
								<div>
									{project?.number_of_staff ||
										"No information"}
								</div>
								<div>Number of users</div>
								<div>
									{project?.number_of_users ||
										"No information"}
								</div>
							</div>
							<Accordion categories={project!.categories} />
						</div>
						<div className="modal modal-footer">
							<button
								className="btn alert"
								onClick={() => {
									handleShow(false);
								}}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default InfoModal;
