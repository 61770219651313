import { LatLng } from "leaflet";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Marker } from "react-leaflet";
import IProject from "../../interfaces/Project";
import DefaultIcon from "./Icon";

interface DraggableMarkerProps {
	handlePosition:
		| React.Dispatch<
				React.SetStateAction<Partial<IProject | null | undefined>>
		  >
		| undefined;
	form: Partial<IProject | null | undefined>;
}

const DraggableMarker: React.FC<DraggableMarkerProps> = ({
	handlePosition,
	form,
}) => {
	let defaultLocation = new LatLng(53.14512298931688, 9.69062805175783);
	const [position, setPosition] = useState<LatLng>(defaultLocation);
	const markerRef = useRef<any>(null);

	useEffect(() => {
		if (form?.latitude && form?.longitude) {
			defaultLocation.lat = form?.latitude;
			defaultLocation.lng = form?.longitude;
			setPosition(defaultLocation);
		}
	}, [defaultLocation.lat, defaultLocation.lng]);

	/**
	 * Events for markers are handled via the eventHandlers property.
	 * Logic has to be defined in its own function.
	 */
	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current;
				if (marker && handlePosition) {
					setPosition(marker.getLatLng());
					handlePosition({
						...form,
						latitude: position?.lat,
						longitude: position?.lng,
					});
				}
			},
		}),
		[form, handlePosition]
	);

	return (
		<Marker
			draggable
			eventHandlers={eventHandlers}
			position={position}
			ref={markerRef}
			icon={DefaultIcon}
		></Marker>
	);
};

export default DraggableMarker;
