import axios from "axios";
import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { toast } from "react-toastify";
import { QuestionCircle, XLg } from "react-bootstrap-icons";
import Tag from "./../tag/Tag";
import "./__modal.scss";
import IProject from "../../interfaces/Project";
import ICountry from "../../interfaces/Country";
import Map from "./../map/Map";
import { useHistory } from "react-router";
import ICategory from "../../interfaces/Category";
import ReactToolTip from "react-tooltip";

interface EditProps {
	project: Partial<IProject | null | undefined>;
	countries: Array<ICountry> | null | undefined;
	show: boolean;
	editFlag: boolean | null;
	handleShow: React.Dispatch<React.SetStateAction<boolean>>;
	fetchData: () => Promise<void>;
}

const EditModel: React.FC<EditProps> = ({
	show,
	editFlag,
	handleShow,
	project,
	countries,
	fetchData,
}) => {
	const history = useHistory();
	const [formData, setFormData] = useState<
		Partial<IProject | null | undefined>
	>({
		name: "",
		publicId: "",
		start: null,
		end: null,
		description: "",
		link: "",
		number_of_staff: null,
		number_of_members: null,
		number_of_users: null,
		other: "",
		latitude: null,
		longitude: null,
		innovative_character: undefined,
		sustainability: undefined,
		social_interaction: undefined,
		social_cohesion: undefined,
		social_protection: undefined,
		transferability: undefined,
		transition: undefined,
		deleteDate: null,
		country: {},
		categories: [],
	});

	const [categories, setCategories] = useState<Array<ICategory>>([]);

	const fetchCategories = () => {
		axios
			.get("/category")
			.then((res) => {
				setCategories(res.data);
			})
			.catch((error) => {
				console.log("error: ", error);
				return toast.error("error while fetching", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			});
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	useEffect(() => {
		project
			? setFormData(project)
			: setFormData({
					name: "",
					publicId: "",
					start: null,
					end: null,
					description: "",
					link: "",
					number_of_staff: null,
					number_of_members: null,
					number_of_users: null,
					other: "",
					latitude: null,
					longitude: null,
					innovative_character: undefined,
					sustainability: undefined,
					social_interaction: undefined,
					social_cohesion: undefined,
					social_protection: undefined,
					transferability: undefined,
					transition: undefined,
					deleteDate: undefined,
					country: {},
					categories: [],
			  });
	}, [project]);

	/**
	 * Listener for Key Events.
	 * Handles Escape Key for closing Modal
	 */
	useEffect(() => {
		const close = (e: { key: string }) => {
			if (e.key === "Escape") {
				handleShow(false);
			}
		};
		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close); //Cleanup function on close
	}, []);

	const handleInputChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormData({
			...formData,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const handleNumberChange = (
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		switch (event.currentTarget.name) {
			case "country": {
				const find = countries?.find(
					(item) => item.id === parseInt(event.currentTarget.value)
				);
				setFormData({ ...formData, [event.currentTarget.name]: find });
				break;
			}
			default: {
				setFormData({
					...formData,
					[event.currentTarget.name]: parseInt(
						event.currentTarget.value
					),
				});
				break;
			}
		}
	};

	const handleLatLngChange = (event: ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			[event.currentTarget.name]: parseFloat(event.currentTarget.value),
		});
	};

	const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.name) {
			case "innovative-character":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							innovative_character: true,
					  })
					: setFormData({
							...formData,
							innovative_character: false,
					  });
				break;
			case "social-interaction":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							social_interaction: true,
					  })
					: setFormData({
							...formData,
							social_interaction: false,
					  });
				break;
			case "social-protection":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							social_protection: true,
					  })
					: setFormData({
							...formData,
							social_protection: false,
					  });
				break;
			case "social-cohesion":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							social_cohesion: true,
					  })
					: setFormData({
							...formData,
							social_cohesion: false,
					  });
				break;
			case "sustainability":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							sustainability: true,
					  })
					: setFormData({
							...formData,
							sustainability: false,
					  });
				break;
			case "transition":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							transition: true,
					  })
					: setFormData({
							...formData,
							transition: false,
					  });
				break;
			case "transferability":
				parseInt(event.currentTarget.value) === 1
					? setFormData({
							...formData,
							transferability: true,
					  })
					: setFormData({
							...formData,
							transferability: false,
					  });
				break;
		}
	};

	const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
		if (editFlag) {
			axios
				.put(`project/${formData?.publicId}`, formData)
				.then((res) => {
					fetchData();
					handleShow(false);
					history.push("/home");
					return toast.success(`Successfully updated ${res.data}`, {
						position: toast.POSITION.BOTTOM_CENTER,
						closeOnClick: true,
						hideProgressBar: false,
						draggable: true,
						progress: undefined,
					});
				})
				.catch((error) => {
					console.log("error: ", error);
					error.response.status === 401
						? toast.error("Unauthorized", {
								position: toast.POSITION.BOTTOM_CENTER,
								closeOnClick: true,
								hideProgressBar: false,
								draggable: true,
								progress: undefined,
						  })
						: toast.error("error while updating", {
								position: toast.POSITION.BOTTOM_CENTER,
								closeOnClick: true,
								hideProgressBar: false,
								draggable: true,
								progress: undefined,
						  });
					return toast;
				});
		} else {
			axios
				.post("project", formData)
				.then((res) => {
					console.log("response: ", res.status);
					fetchData();
					handleShow(false);
					history.push("/home");
					return toast.success(`Successfully saved ${res.data}`, {
						position: toast.POSITION.BOTTOM_CENTER,
						closeOnClick: true,
						hideProgressBar: false,
						draggable: true,
						progress: undefined,
					});
				})
				.catch((error) => {
					console.log("error: ", error);
					error.response.status === 401
						? toast.error("Unauthorized", {
								position: toast.POSITION.BOTTOM_CENTER,
								closeOnClick: true,
								hideProgressBar: false,
								draggable: true,
								progress: undefined,
						  })
						: toast.error("error while saving", {
								position: toast.POSITION.BOTTOM_CENTER,
								closeOnClick: true,
								hideProgressBar: false,
								draggable: true,
								progress: undefined,
						  });
					return toast;
				});
		}
	};

	const checkEqual = (object: { id: number }): boolean | undefined => {
		if (formData?.categories !== undefined) {
			return formData!.categories!.some((category) => {
				return category.id === object.id;
			});
		}
	};
	if (show) {
		return (
			<>
				<div className="container flex fd-c modal">
					<div className="modal modal-content">
						<div className="container grid column-90-10 modal-header modal-header">
							<h2 className="modal modal-title">
								{project?.name || "Add a new project"}
							</h2>
							<button
								className="btn alert"
								onClick={() => {
									handleShow(false);
								}}
							>
								<XLg />
							</button>
						</div>
						<div className="container grid column half-half">
							<label>Name</label>
							<input
								type="text"
								placeholder={"Insert a name"}
								value={formData?.name || ""}
								name="name"
								onChange={handleInputChange}
							/>
							<label>Start</label>
							<input
								type="text"
								placeholder={"Insert a year"}
								value={formData?.start || ""}
								name="start"
								onChange={handleNumberChange}
							/>
							<label>End</label>
							<input
								type="text"
								placeholder={"Insert a year"}
								value={formData?.end || ""}
								name="end"
								onChange={handleNumberChange}
							/>
							<label>Description</label>
							<textarea
								rows={5}
								cols={4}
								placeholder={"Provide a description"}
								value={formData?.description || ""}
								name="description"
								onChange={handleInputChange}
							/>
							<label>Website</label>
							<input
								type="text"
								placeholder={"Provide a website"}
								value={formData?.link || ""}
								name="link"
								onChange={handleInputChange}
							/>
							<label>Country</label>
							<select
								name="country"
								value={formData!.country?.id || ""}
								onChange={handleNumberChange}
							>
								<option value="">Select a country</option>
								{countries?.map((country: any, index: any) => {
									return (
										<option key={index} value={country.id}>
											{country.name}
										</option>
									);
								})}
							</select>
							<label>Geo-Data</label>
							<div className="map-container">
								<div className="container flex lnglat">
									<label>Latitude</label>
									<input
										type="number"
										step="0.01"
										value={formData?.latitude || ""}
										placeholder={"Latitude"}
										onChange={handleLatLngChange}
										name="latitude"
									/>
									<label>Longitude</label>
									<input
										type="number"
										step="0.01"
										value={formData?.longitude || ""}
										placeholder={"Longitude"}
										onChange={handleLatLngChange}
										name="longitude"
									/>
								</div>
								<Map
									editFlag={true}
									setPosition={setFormData}
									form={formData}
								/>
							</div>
							<label>Number of staff</label>
							<input
								type="text"
								placeholder={
									"How many staff members are in this project?"
								}
								value={formData?.number_of_staff || undefined}
								name="number_of_staff"
								onChange={handleNumberChange}
							/>
							<label>Number of members</label>
							<input
								type="text"
								placeholder={
									"How many members are in this project?"
								}
								value={formData?.number_of_members || undefined}
								name="number_of_members"
								onChange={handleNumberChange}
							/>
							<label>Number of users</label>
							<input
								type="text"
								placeholder={
									"How many users are in this project?"
								}
								value={formData?.number_of_users || undefined}
								name="number_of_users"
								onChange={handleNumberChange}
							/>
							<label>Other</label>
							<textarea
								value={formData?.other || ""}
								name="other"
								onChange={handleInputChange}
							/>
							<div>
								<label>Innovative character</label>
								<button
									className="btn transparent"
									data-tip
									data-for="innovative-character-tip"
								>
									<QuestionCircle />
								</button>
								<ReactToolTip
									id="innovative-character-tip"
									place="top"
									effect="solid"
									backgroundColor="#0452aa"
								>
									Does the service parctice appear to be
									something new in the field of social
									services?
								</ReactToolTip>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="innovative-character"
										value={1}
										checked={formData?.innovative_character}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="innovative character"
										value={0}
										checked={
											formData?.innovative_character ===
											false
										}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<div>
								<label>
									More social interaction for mutual support?
								</label>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="social-interaction"
										value={1}
										checked={formData?.social_interaction}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="social-interaction"
										value={0}
										checked={
											formData?.social_interaction ===
											false
										}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<div>
								<label>Higher social protection?</label>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="social-protection"
										value={1}
										checked={formData?.social_protection}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="social-protection"
										value={0}
										checked={
											formData?.social_protection ===
											false
										}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<div>
								<label>
									More social and community cohesion in civil
									society?
								</label>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="social-cohesion"
										value={1}
										checked={formData?.social_cohesion}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="social-cohesion"
										value={0}
										checked={
											formData?.social_cohesion === false
										}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<div>
								<label>Sustainability of innovation?</label>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="sustainability"
										value={1}
										checked={formData?.sustainability}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="sustainability"
										value={0}
										checked={
											formData?.sustainability === false
										}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<div>
								<label>
									Is there a transition from institutional to
									community based care?
								</label>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="transition"
										value={1}
										checked={formData?.transition}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="transition"
										value={0}
										checked={formData?.transition === false}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<div>
								<label>
									Transferability of innovations between
									national contexts possible?
								</label>
							</div>
							<div className="container flex">
								<div className="inputGroup">
									<label>Yes</label>
									<input
										type="radio"
										name="transferability"
										value={1}
										checked={formData?.transferability}
										onChange={handleRadioChange}
									/>
								</div>
								<div className="inputGroup">
									<label>No</label>
									<input
										type="radio"
										name="transferability"
										value={0}
										checked={
											formData?.transferability === false
										}
										onChange={handleRadioChange}
									/>
								</div>
							</div>
							<Tag
								title="Field of service"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Logic of service"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Activity of service"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Status of the provider organization"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Target group"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Type of innovation"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Origins of innovation"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Effectiveness from a user perspective"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Cost effectiveness"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Quality of life"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="More inclusion"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Participation of the user"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
							<Tag
								title="Social return on investment"
								categories={categories}
								form={formData}
								setForm={setFormData}
								isActive={checkEqual}
							/>
						</div>

						<div className="modal modal-footer">
							<button
								className="btn alert"
								onClick={() => {
									handleShow(false);
								}}
							>
								Close
							</button>
							<button
								className="btn success"
								onClick={handleSubmit}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default EditModel;
