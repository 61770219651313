import axios from "axios";
import React from "react";
import { XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import IProject from "../../interfaces/Project";
import "./__modal.scss";

interface YesNoProps {
	project: Partial<IProject | null>;
	show: boolean;
	handleShow: React.Dispatch<React.SetStateAction<boolean>>;
	fetchData: () => Promise<void>;
}
const YesNoModal: React.FC<YesNoProps> = ({
	project,
	show,
	handleShow,
	fetchData,
}) => {
	const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
		axios
			.delete(`/project/${project?.publicId}`)
			.then((res) => {
				fetchData();
				handleShow(false);
				return toast.success(`Successfully deleted ${res.data}`, {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((error) => {
				console.log("error: ", error);
				return toast.error("error while deleting", {
					position: toast.POSITION.BOTTOM_CENTER,
					closeOnClick: true,
					hideProgressBar: false,
					draggable: true,
					progress: undefined,
				});
			});
	};
	if (show) {
		return (
			<>
				<div className="container flex modal">
					<div className="modal modal-content">
						<div className="container grid column-90-10 modal-header">
							<h2 className="modal modal-title">
								{project?.name}
								<button
									className="btn alert"
									onClick={() => {
										handleShow(false);
									}}
								>
									<XLg />
								</button>
							</h2>
						</div>
						<div className="modal modal-body">
							<div className="container flex">
								{`Do you really want to delete ${project?.name} ?`}
							</div>
						</div>
						<div className="modal modal-footer">
							<button
								className="btn alert"
								onClick={() => {
									handleShow(false);
								}}
							>
								No
							</button>
							<button
								className="btn success"
								onClick={handleDelete}
							>
								Yes
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default YesNoModal;
