import React, { useState } from "react";
import ICategory from "../../interfaces/Category";
import IProject from "../../interfaces/Project";
import "./__tag.scss";

interface TagProps {
	title: string;
	categories: Array<ICategory>;
	isActive: (object: { id: number }) => boolean | undefined;
	setForm: React.Dispatch<
		React.SetStateAction<Partial<IProject | null | undefined>>
	>;
	form: Partial<IProject | null | undefined>;
}

const Tag: React.FC<TagProps> = ({
	title,
	categories,
	form,
	setForm,
	isActive,
}) => {
	const [active, setActive] = useState<boolean | undefined>(false);

	return (
		<>
			<label>{title}</label>
			<div className="tag-container">
				{categories
					.filter((category) => {
						return (
							category.type ===
							title[0].toLowerCase() + title.substring(1)
						);
					})
					.map((category, index) => {
						return (
							<div
								className={`tag${
									isActive(category) ? " active " : ""
								}`}
								onClick={() => {
									let copy = form?.categories;
									if (active) {
										setForm({
											...form,
											categories: copy?.filter((item) => {
												return item.id !== category.id;
											}),
										});
									} else {
										copy?.push(category as ICategory);
										setForm({ ...form, categories: copy });
									}
									setActive(!active);
								}}
								key={index}
							>
								<span>{category.name}</span>
								{isActive(category) ? (
									<span className="close-tag">x</span>
								) : null}
							</div>
						);
					})}
			</div>
		</>
	);
};

export default Tag;
