import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Register from "./components/Register";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "./css/__app.scss";

const App = () => {
	return (
		<>
			<div className="App">
				<HelmetProvider>
					<Helmet>
						<link
							rel="stylesheet"
							href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
							integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
							crossOrigin=""
						/>
						<script
							src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
							integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
							crossOrigin=""
						></script>
					</Helmet>
					<Router>
						<Switch>
							<Route exact path="/">
								<Login />
							</Route>
							<Route exact path="/home">
								<Home />
							</Route>
							<Route exact path="/register">
								<Register />
							</Route>
						</Switch>
					</Router>
				</HelmetProvider>
				<ToastContainer
					position="bottom-center"
					autoClose={2500}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick={true}
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
		</>
	);
};

export default App;
