
import "./../css/__footer.scss"
import UniTrierLogo from "./../assets/logos/Uni_Logo_trier.png";
import UniOsloLogo from "./../assets/logos/VID_logo_oslo.png";
import UniHeidelbergLogo from "./../assets/logos/Logo_DWI_heidelberg.png"
import ErasmusLogo from "./../assets/logos/ErasmusPlus Logo.png";
import EurodiaconicaLogo from "./../assets/logos/eurodiaconia_logo.png";
import EuskampusLogo from "./../assets/logos/EuskampusLogo.png";
import NADAADLogo from "./../assets/logos/NADAAD Logo.png";

const Footer:React.FC<any>= () => {

    return(
        <>
            <div className="footer-container">
                <div className="footer-container unilogo">
                    <a href="https://www.uni-trier.de/universitaet/fachbereiche-faecher/fachbereich-i/faecher-und-institute/erziehungs-und-bildungswissenschaften/erziehungswissenschaft/abteilungen/organisationspaedagogik/start" target="_blank" rel="noreferrer">
                        <img src={UniTrierLogo} alt="University Trier Logo"/>
                    </a>
                    <a href="https://www.vid.no/en/" target="_blank" rel="noreferrer">
                        <img src={UniOsloLogo} alt="University Oslo Logo"/>
                    </a>
                    <a href="https://www.dwi.uni-heidelberg.de/aktuelles/" target="_blank" rel="noreferrer"> 
                        <img src={UniHeidelbergLogo} alt="University Heidelberg Logo"/>
                    </a>
                </div>
                <div className="footer-container sponsor">
                    <a href="https://www.erasmusplus.de/" target="_blank" rel="noreferrer">
                        <img src={ErasmusLogo} alt="Erasmus Plus Logo"/>
                    </a>
                    <a href="https://www.eurodiaconia.org/de/" target="_blank" rel="noreferrer">
                        <img src={EurodiaconicaLogo} alt="Eurodiaconcica Logo"/>
                    </a>
                    <a href="https://euskampus.eus/en/front-page?set_language=en" target="_blank" rel="noreferrer">
                        <img src={EuskampusLogo} alt="Euskampus Logo"/>
                    </a>
                    <a href="https://eu.daad.de/de/" target="_blank" rel="noreferrer">
                        <img src={NADAADLogo} alt="NA DAAD Logo"/>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Footer;